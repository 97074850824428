
import React from 'react'
import Layout from '../layouts/layout'
import SEO from '../components/seo'
import SecondaryNav from '../components/secondarynav'

import BorderedBox from '../components/borderedbox'
import TitleHeader from '../components/titleheader'
import Card from '../components/card'
import { ColBuild } from '../components/ColBuild'

import headerimage from '../assets/images/corporate-responsibility/Corporate_Responsibility_Philantrophic_Header.jpg'
import mbheaderimage from '../assets/images/corporate-responsibility/Corporate_Responsibility_Philanthropic_MB_03.jpg'

import corporatephilanthropicimage2 from '../assets/images/corporate-responsibility/Corporate_Responsibility_Philantrophic_03.jpg'
import corporatephilanthropicimage3 from '../assets/images/corporate-responsibility/Corporate_Responsibility_Philantrophic_07.jpg'
import corporatephilanthropicimage4 from '../assets/images/corporate-responsibility/Corporate_Responsibility_Philantrophic_11.jpg'
import corporatephilanthropicimage5 from '../assets/images/corporate-responsibility/Corporate_Responsibility_Philantrophic_15.jpg'
import corporatephilanthropicimage6 from '../assets/images/corporate-responsibility/Corporate_Responsibility_Philantrophic_19.jpg'
import corporatephilanthropicimage7 from '../assets/images/corporate-responsibility/Corporate_Responsibility_Philantrophic_21.jpg'
import corporatephilanthropicimage8 from '../assets/images/corporate-responsibility/NAACP_EmpowermentPrograms-Blue-CMYK-01.png'
import corporatephilanthropicimage9 from '../assets/images/corporate-responsibility/Corporate_Responsibility_Philantrophic_29.jpg'
import corporatephilanthropicimage10 from '../assets/images/corporate-responsibility/Corporate_Responsibility_Philantrophic_32.jpg'
import corporatephilanthropicimage11 from '../assets/images/corporate-responsibility/Corporate_Responsibility_Philantrophic_35.jpg'
import corporatephilanthropicimage12 from '../assets/images/corporate-responsibility/Corporate_Responsibility_Philantrophic_23.jpg'

import { trackPageData, getPageDetails } from '../analytics';

class PhilanthropicPrograms extends React.PureComponent {

  constructor(props) {
    super(props);

    let dataManager = {
      pageDetails: {}
    };
    if (typeof window !== `undefined`) {
      if (window.dataManager) {
        dataManager = window.dataManager;
      }
    }
    dataManager.pageDetails = getPageDetails();
  }

  componentDidMount() {
    trackPageData();
  }

  render() {
    return (
      <Layout>
        <SEO title="Philanthropic Programs |The Children's Place Corporate Website" description="The Children’s Place philanthropic programs donate clothing, money and time to local and national organizations that support children and families in need." />
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <TitleHeader image={headerimage} mbheaderimage={mbheaderimage} title="CORPORATE RESPONSIBILITY" addClass="corporateTitle" />
          </div>
        </div>

        <SecondaryNav
          active="Philanthropic Programs"
          name1="Responsible Sourcing" link1="responsible-sourcing"
          name2="Philanthropic Programs" link2="/philanthropic-programs"
        />

        <div class="row mb-5">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <BorderedBox>
              <h2>THE PLACE FOR GIVING</h2>
              <p>
                As part of our philanthropic mission, The Children’s Place donates clothing, money and time to local and national organizations that support children and families in need.
                </p>
            </BorderedBox>
          </div>
        </div>
        <div class="row mb-2 text-center">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <h1>HOW WE DO IT...</h1>
          </div>
        </div>

        <div id="philanthropicgroup">

          <div class="row mb-5 text-center">
            <div class="col-md-6 col-sm-6 col-xs-6 ">
              <img src={corporatephilanthropicimage2} title="" className="img-fluid" alt="..." />
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6">
              <Card
                title="CLOTHING DONATIONS"
                description="Throughout the year, we create numerous product experiences in our corporate “mock store” using thousands of styles. After each season, we donate all this clothing to those who need it most."
              >
                <button class="btn btn-primary more-less" type="button" data-toggle="collapse" data-target="#philanthropicMoreone" aria-expanded="false" aria-controls=""></button>
              </Card>
            </div>
          </div>

          <div class="row mb-5 collapse" id="philanthropicMoreone" data-parent="#philanthropicgroup">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <BorderedBox classname="mb-n-border">
                <button class="btn close-button" type="button" data-toggle="collapse" data-target="#philanthropicMoreone" aria-expanded="false" aria-controls="">X</button>
                <div class="row">
                  <ColBuild description="We are the largest single donor of children’s clothing to the Good+ Foundation, a national organization that provides clothing and other supplies to families living in poverty." className="philanthropic-border-right" />
                  <ColBuild description="Our pajamas get added to care packages sent monthly to Iron Matt’s family assistance program, providing happiness for kids being treated for pediatric brain cancer." className="philanthropic-border-right" />
                  <ColBuild description="We donate larger-sized products to kids in group homes through Youth Consultation Services (YCS) and newborn clothing to the Children’s Aid and Family Services “Baby Basics” program." />

                </div>
              </BorderedBox>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-5">
              <hr class="d-md-none border-top" />
            </div>
          </div>

          <div class="row mb-5 text-center">
            <div class="col-md-6 col-sm-6 col-xs-6 d-md-none">
              <img src={corporatephilanthropicimage3} title="" className="img-fluid" alt="..." />
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6" >
              <Card
                title="ASSOCIATE DONATIONS"
                description="Year after year, our employees raise the bar and donate more of their time, money and services to help kids."
              >
                <button class="btn btn-primary more-less" type="button" data-toggle="collapse" data-target="#philanthropicMoretwo" aria-expanded="false" aria-controls=""></button>
              </Card>
            </div>

            <div class="col-md-6 col-sm-6 col-xs-6 d-none d-md-flex">
              <img src={corporatephilanthropicimage3} title="" className="img-fluid" alt="..." />
            </div>
          </div>


          <div class="row mb-5 collapse" id="philanthropicMoretwo" data-parent="#philanthropicgroup">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <BorderedBox classname="mb-n-border">
                <button class="btn close-button" type="button" data-toggle="collapse" data-target="#philanthropicMoretwo" aria-expanded="false" aria-controls="">X</button>

                <div class="row">

                  <ColBuild description="Each summer, Associates help kids prep for school by donating hundreds of backpacks filled with school supplies to our charitable partners." className="philanthropic-border-right" />

                  <ColBuild description="Each winter, Associates grant holiday wishes to over 150 children living in YCS group homes, giving them a chance to experience the joy of the season." className="philanthropic-border-right" />

                  <ColBuild description="All year long, Associates donate to causes and charities close to their hearts. The Children’s Place is pleased match our associates’ donations." />
                </div>
              </BorderedBox>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-5">
              <hr class="d-md-none border-top" />
            </div>
          </div>
          <div class="row mb-5 text-center">

            <div class="col-md-6 col-sm-6 col-xs-6 fill">
              <img src={corporatephilanthropicimage4} title="" className="img-fluid" alt="..." />
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6">
              <Card
                title="CORPORATE GIVING"
                description="Whether it's providing essentials for families, funding pediatric cancer research, or fighting racial injustices and inequities, we are proud to support a variety of philanthropic programs both locally and nationwide."
              >
                <button class="btn btn-primary more-less" type="button" data-toggle="collapse" data-target="#philanthropicMorethree" aria-expanded="false" aria-controls=""></button>
              </Card>
            </div>
          </div>

          <div class="row mb-5 collapse" id="philanthropicMorethree" data-parent="#philanthropicgroup">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <BorderedBox classname="mb-n-border">
                <button class="btn close-button" type="button" data-toggle="collapse" data-target="#philanthropicMorethree" aria-expanded="false" aria-controls="">X</button>

                <div class="row">
                  <div class="col-md-3 col-sm-3 col-xs-12 philanthropic-border-right" >
                    <p>
                      The Children’s Place is a sponsor of the annual “Bag A Lunch, Help A Bunch” program and our matching Associate donations helped us become the largest financial contributor to this annual event.
                  </p>
                  </div>

                  <div class="col-md-3 col-sm-3 col-xs-12 philanthropic-border-right" >
                    <p>
                      During Pediatric Cancer Month (September), The Children’s Place helps sponsor the Iron Matt Foundation’s annual event which, raises money to fund pediatric brain cancer research and support families of children being treated for this terminal condition.
                  </p>
                  </div>
                  <div class="col-md-3 col-sm-3 col-xs-12 philanthropic-border-right">
                    <p>
                      The Children’s Place is a corporate sponsor of the Children’s Aid and Family Services Baby Basics program, which provides newborn essentials for low-income families living or working in northern NJ.
                  </p>
                  </div>
                  <div class="col-md-3 col-sm-3 col-xs-12">
                    <p>
                      The Children’s Place provides corporate donations and matches Associate gifts in support of the NAACP’s mission to secure equality of rights in order to eliminate race-based discrimination and ensure the health and well-being of all persons.
                  </p>
                  </div>
                </div>

              </BorderedBox>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-5">
              <hr class="d-md-none border-top" />
            </div>
          </div>

          <div class="row mb-5 text-center">
            <div class="col-md-6 col-sm-6 col-xs-6 fill d-md-none">
              <img src={corporatephilanthropicimage5} title="" className="img-fluid" alt="..." />
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6">
              <Card
                title="DONATING TIME"
                description="Volunteering is important to our Associates and we provide in-house and out-of-office opportunities to give back."
              >
                <button class="btn btn-primary more-less" type="button" data-toggle="collapse" data-target="#philanthropicMoretfour" aria-expanded="false" aria-controls=""></button>
              </Card>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 fill d-none d-md-flex">
              <img src={corporatephilanthropicimage5} title="" className="img-fluid" alt="..." />
            </div>
          </div>

          <div class="row mb-5 collapse" id="philanthropicMoretfour" data-parent="#philanthropicgroup">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <BorderedBox classname="mb-n-border">
                <button class="btn close-button" type="button" data-toggle="collapse" data-target="#philanthropicMorefour" aria-expanded="false" aria-controls="">X</button>

                <div class="row">
                  <ColBuild description="Each month, Associates create care packages full of pajamas and gift cards for Iron Matt families that have a child being treated for brain cancer." className="philanthropic-border-right" />

                  <ColBuild description="Interns and Associates spend a day at camp playing with the Youth Consultation Services’ campers, which is particularly special for the kids since their group home setting makes one-on-one companionship a rarity." className="philanthropic-border-right" />

                  <ColBuild description="Throughout the year, Associates volunteer at the Good+ Foundation’s warehouse to bundle our donated clothing into head-to-toe outfits for recipient families." className="" />
                </div>
              </BorderedBox>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-5">
              <hr class="d-md-none border-top" />
            </div>
          </div>
          <div class="row mb-5 text-center">
            <div class="col-12"><p><small>Learn more about our philanthropic partners by clicking their logos below.</small></p></div>

            <div class="col-md-4 col-sm-4 col-xs-4">
              <button class="btn close-button" type="button" data-toggle="collapse" data-target="#DonateMoreOne" aria-expanded="false" aria-controls=""> <img src={corporatephilanthropicimage6} title="" className="img-fluid" alt="..." /></button>
            </div>

            <div class="col-md-4 col-sm-4 col-xs-4">
              <button class="btn close-button" type="button" data-toggle="collapse" data-target="#DonateMoreTwo" aria-expanded="false" aria-controls=""> <img src={corporatephilanthropicimage7} title="" className="img-fluid" alt="..." /></button>
            </div>


            <div class="col-md-4 col-sm-4 col-xs-4">
              <button class="btn close-button" type="button" data-toggle="collapse" data-target="#DonateMoreThree" aria-expanded="false" aria-controls=""> <img src={corporatephilanthropicimage8} title="" className="img-fluid" alt="..." /></button>
            </div>
          </div>

          <div class="row mb-5 collapse" id="DonateMoreOne" data-parent="#philanthropicgroup">
            <div class="col-md-12 col-sm-12 col-xs-12">
              {/* GOOD+ Foundation */}
              <BorderedBox classname="mb-n-border">
                <button class="btn close-button" type="button" data-toggle="collapse" data-target="#DonateMoreOne" aria-expanded="false" aria-controls="">X</button>
                <p>
                  Since 2001, The Children’s Place has donated more than $2M in clothing to the Good+ Foundation to distribute  throughout their national network of partners. In 2017, we began sponsoring their “Shop with Pops” program by providing dads with gift cards to use during a day of shopping and bonding with their kids.
                <a href="https://goodplusfoundation.org/">Learn More</a>
                </p>
              </BorderedBox>
            </div>
          </div>

          <div class="row mb-5 collapse" id="DonateMoreTwo" data-parent="#philanthropicgroup">
            {/* IronMatt (The Matthew Larson Foundation)  */}
            <div class="col-md-12 col-sm-12 col-xs-12">
              <BorderedBox classname="mb-n-border">
                <button class="btn close-button" type="button" data-toggle="collapse" data-target="#DonateMoreTwo" aria-expanded="false" aria-controls="">X</button>
                <p>
                  The Children’s Place is proud to help families supported by IronMatt’s assistance program.  Every month, Associates pack up new pajamas for each “IronKid” undergoing pediatric brain cancer treatment. $100 Gift cards are also provided to each IronKid (and their siblings!) so they can all treat themselves to new outfits.  In addition, we donate to Iron Matt’s annual fundraising event.
                <a href="https://www.ironmatt.org/home">Learn More</a>
                </p>
              </BorderedBox>
            </div>
          </div>


          <div class="row mb-5 collapse" id="DonateMoreThree" data-parent="#philanthropicgroup">
            {/* Youth Consultation Services (YCS)  */}
            <div class="col-md-12 col-sm-12 col-xs-12">
              <BorderedBox classname="mb-n-border">
                <button class="btn close-button" type="button" data-toggle="collapse" data-target="#DonateMoreThree" aria-expanded="false" aria-controls="">X</button>
                <p>
                  The Children’s Place provides corporate donations and matches Associate gifts in support of the NAACP’s mission to secure equality of rights in order to eliminate race-based discrimination and ensure the health and well-being of all persons. <a href="http://www.ycs.org/">Learn More</a>
                </p>
              </BorderedBox>
            </div>
          </div>


          <div class="row mb-5 text-center">
            <div class="col-md-3 col-sm-3 col-xs-4">
              <button class="btn close-button text-center" type="button" data-toggle="collapse" data-target="#DonateMoreFour" aria-expanded="false" aria-controls=""> <img src={corporatephilanthropicimage9} title="" className="img-fluid" alt="..." /></button>
            </div>

            <div class="col-md-3 col-sm-3 col-xs-4">
              <button class="btn close-button text-center" type="button" data-toggle="collapse" data-target="#DonateMoreFive" aria-expanded="false" aria-controls=""> <img src={corporatephilanthropicimage11} title="" className="img-fluid" alt="..." /></button>
            </div>

            <div class="col-md-3 col-sm-3 col-xs-4">
              <button class="btn close-button text-center" type="button" data-toggle="collapse" data-target="#DonateMoreSix" aria-expanded="false" aria-controls=""> <img src={corporatephilanthropicimage12} title="" className="img-fluid" alt="..." /></button>
            </div>

            <div class="col-md-3 col-sm-3 col-xs-4">
              <button class="btn close-button text-center" type="button" data-toggle="collapse" data-target="#DonateMoreSeven" aria-expanded="false" aria-controls=""> <img src={corporatephilanthropicimage10} title="" className="img-fluid" alt="..." /></button>
            </div>
          </div>

          <div class="row mb-5 collapse" id="DonateMoreFour" data-parent="#philanthropicgroup">
            {/* Table to Table  */}
            <div class="col-md-12 col-sm-12 col-xs-12">
              <BorderedBox classname="mb-n-border">
                <button class="btn close-button" type="button" data-toggle="collapse" data-target="#DonateMoreFour" aria-expanded="false" aria-controls="">X</button>
                <p>
                  The Children’s Place is a Table to Table Silver Sponsor and the top donor to the yearly “Bag a Lunch, Help a Bunch” program.  And every Thanksgiving, our Associates donate money they would have spent on lunch to Table to Table which The Children’s Place matches.
                  <a href="https://tabletotable.org/who-we-are/mission/">Learn More</a>
                </p>
              </BorderedBox>
            </div>
          </div>

          <div class="row mb-5 collapse" id="DonateMoreFive" data-parent="#philanthropicgroup">
            {/* Children's Aid and Family Services (CAFS) */}
            <div class="col-md-12 col-sm-12 col-xs-12">
              <BorderedBox classname="mb-n-border">
                <button class="btn close-button" type="button" data-toggle="collapse" data-target="#DonateMoreFive" aria-expanded="false" aria-controls="">X</button>
                <p>
                  In 2019, The Children’s Place became the corporate sponsor of the CAFS “Baby Basics” program which provides diapers, formula and other necessities to children up to the age of 2.
                  <a href="http://cafsnj.org/">Learn More</a>
                </p>
              </BorderedBox>
            </div>
          </div>


          <div class="row mb-5 collapse" id="DonateMoreSix" data-parent="#philanthropicgroup">
            {/* Alex’s Lemonade Stand */}
            <div class="col-md-12 col-sm-12 col-xs-12">
              <BorderedBox classname="mb-n-border">
                <button class="btn close-button" type="button" data-toggle="collapse" data-target="#DonateMoreSix" aria-expanded="false" aria-controls="">X</button>
                <p>
                Over the past 17 years (and counting!), The Children’s Place has covered the full cost to send twenty children in the YCS program to summer camp. We also donate new clothing to all the YCS kids, as well as grant holiday gift wishes to over 150 children in their programs. <a href="http://www.ycs.org/">Learn More</a>
                </p>
              </BorderedBox>
            </div>
          </div>

          <div class="row mb-5 collapse" id="DonateMoreSeven" data-parent="#philanthropicgroup">
            {/* Secaucus */}
            <div class="col-md-12 col-sm-12 col-xs-12">
              <BorderedBox classname="mb-n-border">
                <button class="btn close-button" type="button" data-toggle="collapse" data-target="#DonateMoreSeven" aria-expanded="false" aria-controls="">X</button>
                <p>
                  Our corporate headquarters is located in the heart of Secaucus, NJ and we are proud to support the Secaucus Youth Alliance by donating clothing throughout the year and providing money to purchase toys for local kids in need during the holidays.
                </p>
              </BorderedBox>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default PhilanthropicPrograms
