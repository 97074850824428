import React from 'react'
import PropTypes from 'prop-types'

const ColBuild = ({description, id, className}) => (
  <div id={id} className={`${className} col-md-4 col-sm-4 col-xs-12`}>
    <p>
      {description}
    </p>
  </div>
)

ColBuild.propTypes = {
description: PropTypes.string.isRequired,
className: PropTypes.string,

}

export {ColBuild}
